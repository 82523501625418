<script lang="ts" setup>
import type { SubjectCode } from '~/models/Subject'
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { colorMap, getColorAndShade } from '~/utils/colors'
import useSubjectsStore from '~/stores/subjects'
import { useSubjectViewStore } from '~/stores/subjectView'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { PendoFeature } from '~/models/Pendo'
import { useContentFilter } from '~/composables/useContentFilter'
import { useAppColor } from '~/composables/useAppColor'
import TeacherButton from '~/components/subject/TeacherButton.vue'
import SubjectSearch from '~/components/subject/SubjectSearch.vue'
import PreviousVisit from '~/components/subject/PreviousVisit.vue'
import ColophonButton from '~/components/subject/ColophonButton.vue'
import ListCard from '~/components/cards/ListCard.vue'
import SubjectFilter from '~/components/ResourceFinder/SubjectFilter.vue'
import GradeFilter from '~/components/ResourceFinder/GradeFilter.vue'

const props = defineProps<{ subjectCode: SubjectCode }>()

const { t } = useI18n()
const { filteredContents }  = useContentFilter()
const { selectedGrade, isLoading } = storeToRefs(useFilterStore())
const { isTeacher } = storeToRefs(useAuthStore())
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
const { loadSubjectData } = useSubjectViewStore()
const { subjectHeader, loadingSubjectData } = storeToRefs(useSubjectViewStore())
const { currentSubject } = storeToRefs(useSubjectsStore())

const { clear: clearAppColor, set: setAppColor } = useAppColor()

const showTeacherButton = computed(() => isTeacher.value
  && hasLoadedProducts.value
  && selectedGrade.value
  && props.subjectCode)

const themeColor = computed(() => {
  const subjectColorMap: Record<SubjectCode, string> = {
    'SAF': 'Blue',
    'SPA': 'Coral'
  }

  return subjectColorMap[currentSubject.value as string] || 'Blue'
})

const themeColorValue = computed(() => getColorAndShade(themeColor.value, '50'))
const skeletonColor = computed(() => colorMap.get(getColorAndShade(themeColor.value, '10'))?.rgb)
const cardStyle = computed(() => ({
  '--card-bg': colorMap.get(getColorAndShade(themeColor.value, '5'))?.rgb,
  '--card-text': colorMap.get(getColorAndShade(themeColor.value, '60'))?.rgb,
  '--card-border': colorMap.get(getColorAndShade(themeColor.value, '20'))?.rgb,
}))

onMounted(() => setAppColor(themeColorValue.value))

// Update app color when changing subject from dropdown
watch(themeColor, () => {
  if (!themeColor.value) return
  clearAppColor()
  setAppColor(themeColorValue.value)
})

watch(props, () => {
  if (!props.subjectCode) return
  loadSubjectData(props.subjectCode)
}, { immediate: true })
</script>

<template>
  <div class="relative mx-auto mb-20 h-full w-full max-w-screen-au px-4 sm:px-8">
    <div class="mx-auto mb-16 flex-1 space-y-16 pt-24 transition-all">
      <section class="mx-auto max-w-screen-lg space-y-12">
        <div class="flex w-full flex-wrap gap-4">
          <SubjectFilter />
          <GradeFilter />
        </div>
        <!-- TODO: PreviousVisit functionality, hidden for now -->
        <PreviousVisit
          v-if="false"
          :subject-code="subjectCode"
        />
        <!-- Subjectheader is placeholder for PreviousVisit -->
        <KsSkeletonWrapper v-if="loadingSubjectData">
          <KsSkeleton
            height="16rem"
            :background="skeletonColor"
          />
        </KsSkeletonWrapper>
        <div
          v-if="!loadingSubjectData && subjectHeader"
          class="relative h-64 w-full overflow-hidden rounded-xl"
        >
          <img
            :src="subjectHeader.image"
            alt=""
            class="h-full w-full object-cover"
          >
          <h1
            class="absolute left-24 top-1/2 -translate-y-1/2 font-bold text-white fluid-text-3xl"
            v-text="t(subjectHeader.title)"
          />
        </div>
      </section>

      <section class="space-y-4">
        <div class="flex items-end justify-between">
          <h1
            class="font-bold text-white fluid-text-lg"
            v-text="t('filters.chooseHeader')"
          />
          <div
            class="flex items-center gap-4"
            style="--ks-border: rgb(var(--au-yellow-30)); color: rgb(var(--au-yellow-30)); --ks-borderhoverfill: rgb(var(--au-yellow-30))"
          >
            <TeacherButton
              v-if="showTeacherButton"
              :subject-code="subjectCode"
              :grade-code="selectedGrade"
              :data-pendo="PendoFeature.TeacherContent.Subject"
            />
            <ColophonButton
              v-if="hasLoadedProducts"
              :subject-code="subjectCode"
              :grade-code="selectedGrade"
              :data-pendo="PendoFeature.Colophon.Subject"
            />
          </div>
        </div>
        <KsSkeletonWrapper
          v-if="isLoading"
          class="grid grid-cols-3 border border-transparent"
        >
          <KsSkeleton
            v-for="i in 12"
            :key="`skeleton-${i}`"
            height="80px"
            :background="skeletonColor"
            class="!rounded-none border border-transparent"
          />
        </KsSkeletonWrapper>
        <ul
          v-else
          class="grid w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
          :style="cardStyle"
        >
          <li
            v-for="content in filteredContents"
            :key="`card-${content.locationId}`"
            class="relative flex w-full"
          >
            <ListCard :resource="content" />
          </li>
        </ul>
      </section>
    </div>
    <SubjectSearch
      v-if="hasLoadedProducts"
      :subject-code="subjectCode"
      :grade-code="selectedGrade"
      :theme-color="themeColor"
    />
  </div>
</template>
