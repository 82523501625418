<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import { useGreetings } from '~/composables/useGreetings'
import { useAppColor } from '~/composables/useAppColor'
import Klover from '~/assets/lottie/KK_Jetpack_Optimized.json'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import LowerPrimarySubjects from '~/components/home/LowerPrimarySubjects.vue'
import HomeRelevantCard from '~/components/home/HomeRelevantCard.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
const { greeting } = useGreetings()

const { themeColor } = useAppColor()
const autoplay = !matchMedia('(prefers-reduced-motion)').matches
</script>

<template>
  <section :class="`bg-${themeColor?.name} text-white`">
    <div class="relative z-20 mx-auto grid h-full w-full max-w-screen-au grid-cols-1 items-center text-clip px-4 pb-10 pt-40 transition-all xs:pb-14 sm:px-8 sm:pt-32 lg:grid-cols-2">
      <div class="space-y-10 lg:pr-6">
        <div>
          <h1
            class="sr-only"
            v-text="t('home.title')"
          />
          <p class="fluid-text-lg">
            {{ greeting.text }}<br>
            <span
              v-if="user?.userData"
              class="font-calistoga fluid-text-5xl"
              v-text="`${user?.userData.firstName}`"
            />
          </p>
        </div>
        <LowerPrimarySubjects />
      </div>
      <div class="pointer-events-none relative bottom-0 right-0 -mb-12 h-full w-full lg:absolute lg:-mb-24 lg:w-1/2 2xl:-right-12">
        <div class="relative flex h-full w-full items-center justify-center md:justify-start">
          <HomeRelevantCard class="pointer-events-auto my-12 self-center lg:absolute lg:bottom-0 lg:left-0 lg:z-10 lg:my-0" />
          <LottieAnimation
            :animation-data="Klover"
            :auto-play="autoplay"
            width="620px"
            height="620px"
            class="hidden md:block lg:absolute lg:bottom-36 lg:right-0 lg:max-w-[50dvw]"
          />
          <div class="absolute bottom-0 right-8 hidden md:block">
            <CopyrightButton
              class="pointer-events-auto"
              :copyright="t('aschehoug')"
              is-animation
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
