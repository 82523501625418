import type { CustomWindow } from '~/models/Window'
import type { User } from '~/models/User/User'
import type { PendoEvents, PendoTrackData, PendoUser } from '~/models/Pendo'
import { UserRole } from '~/models/User/UserRole'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'
import { PendoEventName } from '~/models/Pendo'
import { ContentType } from '~/models/Content/ContentType'
import useEnvironment from '~/composables/useEnvironment'

declare let window: CustomWindow

export default () => {
  const { getEnv } = useEnvironment()

  const viewsWithTrackingEnabled = [
    ContentType.Presentation,
  ]

  const pendoEnabled: boolean = (import.meta.env.VITE_PENDO_ENABLED.toLowerCase() === 'true')
  const buildVisitorId = (user: User) => `${getEnv()}-${user.hash}`
  const buildAccountId = (user: User) => user.organization?.number ? `${getEnv()}-${user.organization.number}` : ''
  const hasSession = (user: User) => window.pendo && window.pendo.getVisitorId() === buildVisitorId(user)

  const getPendoEvents = (): PendoEvents => ({
    ready: () => {
      console.debug('Pendo ready')
      const readyEvent = new Event(PendoEventName.Ready)
      window.dispatchEvent(readyEvent)
    }
  })

  /*
   * If we do not know if the user is a trial user or not, we should not send the trialOnly property.
   * This is because we do not want to overwrite the value if it is already set in Pendo with some default value.
   */
  const buildUser = (user: User, trialOnly: boolean | undefined = undefined): PendoUser => ({
    visitor: {
      id: buildVisitorId(user),
      role: user.userData.role || UserRole.Anonymous,
      organizationName: user.organization?.name || '',
      organizationNumber: user.organization?.number || '',
      loggedIn: true,
      locale: user?.preferredLanguage || PreferredLanguage.Bokmal,
      grades: user?.grades || [],
      readOnlyGrades: user?.userData?.readOnlyGrades || [],
      subjects: user?.subjects || [],
      ...(trialOnly !== undefined && { trialOnly: trialOnly }),
    },
    account: {
      id: buildAccountId(user),
      organizationName: user.organization?.name || '',
      organizationNumber: user.organization?.number || '',
      ...(trialOnly !== undefined && { trialOnly: trialOnly }),
    },
  })

  const buildAnonymousUser = (): PendoUser => ({
    visitor: {
      id: `${getEnv()}-anonymous`,
      role: UserRole.Anonymous,
      organizationName: '',
      organizationNumber: '',
      loggedIn: false,
      locale: PreferredLanguage.Bokmal,
      grades: [],
      readOnlyGrades: [],
      subjects: [],
    },
    account: {
      id: '',
      organizationName: '',
      organizationNumber: '',
    },
    events: getPendoEvents(),
  })

  function initAnonPendo(): void {
    // Add same condition as in initPendo
    console.debug('Initializing Pendo session for anonymous user')
    window.pendo.initialize(buildAnonymousUser())
  }

  function initPendo(user: User): void {
    if (!pendoEnabled) return
    if (!hasSession(user)) {
      console.debug('Initializing Pendo session')
      window.pendo.initialize(buildUser(user))
    }
  }

  function updatePendo(user: User, trialOnly: boolean | undefined = undefined): void {
    if (!pendoEnabled) return
    if (window.pendo) {
      console.debug('Updating Pendo session')
      window.pendo.updateOptions(buildUser(user, trialOnly))
    }
  }

  function pendoTrack(name: string, data: PendoTrackData): void {
    if (!pendoEnabled) return
    if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
      window.pendo.track(name, data)
    } else {
      console.debug('Pendo not ready, track event not sent')
    }
  }

  function pendoTeardown(): void {
    if (window.pendo) {
      window.pendo.teardown()
      console.debug('Pendo teardown complete')
    }
  }

  function pendoFlushNow(): void {
    if (window.pendo) {
      window.pendo.flushNow()
      console.debug('Pendo flush complete')
    }
  }

  return {
    initPendo,
    initAnonPendo,
    updatePendo,
    pendoTrack,
    pendoTeardown,
    pendoFlushNow,
    pendoEnabled,
    viewsWithTrackingEnabled,
  }
}
