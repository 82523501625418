<script lang="ts" setup>
import type { SubjectCode } from '~/models/Subject'
import type { SectionId } from '~/models/Section'
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { setTitle } from '~/utils/dom'
import useSubjectsStore from '~/stores/subjects'
import { useSubjectViewStore } from '~/stores/subjectView'
import { useSectionStore } from '~/stores/section'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import SectionsSkeleton from '~/components/skeletons/SectionsSkeleton.vue'
import SubjectBanner from '~/components/banners/SubjectBanner.vue'
import TeacherResources from '~/components/ResourceFinder/TeacherResources.vue'
import ResourceFinder from '~/components/ResourceFinder/ResourceFinder.vue'
import SectionRenderer from '~/components/ResourceEngine/SectionRenderer.vue'

const { t } = useI18n()
const { selectedGrade, selectedHeaders, isLoading: filtersLoading } = storeToRefs(useFilterStore())
const { setCurrentSubject } = useSubjectsStore()
const { currentSubject } = storeToRefs(useSubjectsStore())
const { isTeacher } = storeToRefs(useAuthStore())
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())

const props = defineProps<{ subjectCode: SubjectCode }>()

const title = t(`metadata.subjects.${props.subjectCode}`)
setTitle(title)

const sectionStore = useSectionStore()
const { loadSections } = sectionStore
const { subjectSections, isLoading: sectionsLoading } = storeToRefs(sectionStore)

const { loadSubjectData } = useSubjectViewStore()
const { subjectHeader } = storeToRefs(useSubjectViewStore())

watch(subjectHeader, (newHeader) => {
  setTitle(t(newHeader.title))
}, { deep: true })

watch(props, () => {
  if (!props.subjectCode) return
  loadSubjectData(props.subjectCode)
    .catch((error:any) => console.warn(error))
  loadSections(props.subjectCode as SectionId)
    .catch((error:any) => console.warn(error))
}, { immediate: true })

onMounted(() => props.subjectCode && setCurrentSubject(props.subjectCode))

const showSections = computed(() => !sectionsLoading.value
  && !filtersLoading.value
  && subjectSections.value.length
  && !selectedHeaders.value.length)

const showTeacherResources = computed(() => isTeacher.value
  && hasLoadedProducts.value
  && !selectedHeaders.value.length
  && selectedGrade.value
  && currentSubject.value)
</script>

<template>
  <SubjectBanner
    :title="t(subjectHeader.title)"
    :image="subjectHeader.image"
    :text="subjectHeader.text"
  />

  <ResourceFinder />

  <TeacherResources
    v-if="showTeacherResources"
    :subject-code="currentSubject"
    :grade-code="selectedGrade"
  />

  <SectionRenderer
    v-if="showSections"
    :items="subjectSections"
  />
  <div
    v-if="sectionsLoading"
    class="mx-auto max-w-screen-au"
  >
    <SectionsSkeleton />
  </div>
</template>
