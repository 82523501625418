import type { SubjectCode, SubjectData } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import type { ContentResource } from '~/models/Content/ContentResource'
import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { lowerPrimaryGrades, lowerSecondaryGrades, sortByGradeIndex } from '~/utils/gradeSorter'
import arrayUtils from '~/utils/arrayUtils'
import useSubjectsStore from '~/stores/subjects'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { useResourceApi } from '~/api/resourceApi'

/**
 * This store is mainly used by the SubjectView component, and holds the data for it. It does not
 * work on the subjects in the filterStore or subjectsStore (where the currentSubject is set).
 */
export const useSubjectViewStore = defineStore('subject', () => {
  const { selectedGrade } = storeToRefs(useFilterStore())
  const { userRelevantGrades } = storeToRefs(useAuthStore())
  const { currentSubject } = storeToRefs(useSubjectsStore())
  const { intersect } = arrayUtils()

  const subjectData = ref<Record<SubjectCode, SubjectData>>({})
  const productData = ref<ContentResource[]>([])
  const loadingSubjectData = ref(false)

  const currentSubjectData = computed((): SubjectData|null => {
    if (!currentSubject.value) return null
    return subjectData.value[currentSubject.value] ?? null
  })

  const subjectHeaders = computed(() => currentSubjectData.value?.headers || [])
  const defaultImage = computed(() => currentSubjectData.value?.resource.image || '')
  const defaultDescription = computed(() => currentSubjectData.value?.resource.description || '')
  const defaultTitle = computed(() => currentSubjectData.value?.resource.shortTitle || currentSubjectData.value?.resource.title || '')

  const subjectHeader = computed(() => {
    const headers = subjectHeaders.value.filter(({ grades }) => selectedGrade.value ? grades.includes(selectedGrade.value) : intersect(grades, userRelevantGrades.value).length)
    headers.sort(({ grades: gradesA }, { grades: gradesB }) => sortByGradeIndex(
      gradesA.sort(sortByGradeIndex)[0], gradesB.sort(sortByGradeIndex)[0],
    ))
    const headerTitle = headers.length ? headers[0].title : defaultTitle.value
    return {
      title: currentSubject.value && currentSubject.value.length === 3
        ? `metadata.subjects.${currentSubject.value}`
        : headerTitle,
      image: headers.length ? headers[0].image : defaultImage.value,
      text: headers.length ? headers[0].description : defaultDescription.value,
    }
  })

  const loadSubjectData = async (subjectCode: SubjectCode): Promise<void> => {
    if (currentSubjectData.value) return
    loadingSubjectData.value = true
    try {
      const { getSubjectResourceByCode, getSubjectHeadersByCode } = useResourceApi()
      const [resource, headers] = await Promise.all([
        getSubjectResourceByCode(subjectCode),
        getSubjectHeadersByCode(subjectCode),
      ])
      subjectData.value[subjectCode] = { resource, headers }
    } finally {
      loadingSubjectData.value = false
    }
  }

  const loadProductData = async (locationId: number): Promise<void> => {
    const { getHeadersByLocationId } = useResourceApi()
    productData.value = await getHeadersByLocationId(locationId)
  }

  /**
   * Check if the given combination of subject and grade should use the new subject view or not
   * @param subject The subject code to check for newness
   * @param grade The grade code to check for newness
   * @returns
   */
  const isNewSubject = (subject: SubjectCode, grade: GradeCode): boolean => {
    if (!subject || !grade) return false

    const newSubjectCombinations = [
      { subject: 'SAF', grades: lowerSecondaryGrades },
      { subject: 'SPA', grades: lowerSecondaryGrades },
      { subject: 'SAF', grades: lowerPrimaryGrades },
    ]

    return newSubjectCombinations.some(({ subject: s, grades }) => s === subject && grades.includes(grade))
  }

  return {
    currentSubjectData,
    subjectHeader,
    loadSubjectData,
    loadProductData,
    productData,
    isNewSubject,
    loadingSubjectData,
  }
})
