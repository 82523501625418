<script setup lang="ts">
import type { ColorPair } from '~/models/Presentation/BasePage'
import type { Image } from '~/models/Content/Image'
import { computed } from 'vue'
import { KsIcon } from '@aschehoug/kloss'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'

const props = defineProps<{
  image?: Image
  classes?: string | string[]
  colorPair?: ColorPair
  copyright?: string
  caption?: string
}>()

const src = computed(() => props.image.src)
const altText = computed(() => props.image.altText)
const _copyright = computed(() => props.copyright ?? props.image.copyright)
const _caption = computed(() => props.caption ?? props.image.caption)
</script>

<template>
  <div class="group relative h-full w-full">
    <slot>
      <img
        v-if="src"
        :src="src"
        :alt="altText"
        :class="classes"
      >
    </slot>

    <div
      v-if="_copyright || _caption"
      class="absolute bottom-0 w-max max-w-prose space-y-1 bg-blue-50 px-6 py-3 text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100"
      :style="{ background: colorPair?.text.rgb, color: colorPair?.background.rgb }"
    >
      <RichTextRenderer
        v-if="_caption"
        :text="_caption"
      />
      <small
        v-if="_copyright"
        class="flex items-center gap-2 text-sm"
      >
        <KsIcon
          id="copyright"
          :scale=".9"
        />
        <span v-text="_copyright" />
      </small>
    </div>
  </div>
</template>

