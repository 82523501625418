export enum ContentType {
  ProductPackage = 'product_package',
  ProductHeader = 'product_header',
  ProductContainer = 'product_container',
  ProductArticle = 'product_article',
  ProductPart = 'product_part',
  Product = 'product',
  SectionExpanded = 'section_expanded',
  SectionStandalone = 'section_standalone',
  SectionBox = 'section_box',
  Folder = 'folder',
  SectionSubjectRowButton = 'section_subject_row_button',
  File = 'file',
  UploadedFile = 'upload_file',
  Link = 'link',
  LearningPath = 'learning_path',
  Video = 'video',
  BlogPost = 'blog_post',
  BookReader = 'book_reader',
  Author = 'author',
  Presentation = 'presentation',
  ThreeStepPage = 'page_three_step',
  GridPage = 'page_grid',
  SideBySidePage = 'page_side_by_side',
  VideoPage = 'page_video',
  ArticlePage = 'page_article',
  TextAndImage = 'content_text_and_image',
  Image = 'image',
  Svg = 'svg',
  FlytTask = 'flyt_task',
  ImageAndMaybeText = 'page_image_and_maybe_text',
  OnlyTextPage = 'page_only_text_no_image',
  AudioPage = 'page_audio',
  Article = 'app_article',
  Audio = 'audio',
  RelatedContentPage = 'page_related_content',
  Lottie = 'lottie_json',
  PdfBook = 'pdf_book',
  TeacherArticle = 'app_article_teacher',
  ArticleContent = 'app_article_content',
  Colophon = 'product_colophon',
}
