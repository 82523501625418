<script lang="ts" setup>
import type { SubjectCode } from '~/models/Subject'
import { useI18n } from 'vue-i18n'
import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { setTitle } from '~/utils/dom'
import useSubjectsStore from '~/stores/subjects'
import { useSubjectViewStore } from '~/stores/subjectView'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import useQueryFilter from '~/composables/useQueryFilter'
import { useAppColor } from '~/composables/useAppColor'
import NewSubjectView from '~/components/subject/NewSubjectView.vue'
import LegacySubjectView from '~/components/subject/LegacySubjectView.vue'
import HeaderView from '~/components/subject/HeaderView.vue'

const props = defineProps<{ subjectCode: SubjectCode }>()

const { t } = useI18n()
const filterStore = useFilterStore()
const { selectedHeaders, selectedGrade } = storeToRefs(filterStore)
const { filterWatchers, $reset } = filterStore
const { hasLoaded } = storeToRefs(useProductStore())
const { setCurrentSubject } = useSubjectsStore()
const { currentSubject } = storeToRefs(useSubjectsStore())
const { isNewSubject } = useSubjectViewStore()

const title = t(`metadata.subjects.${props.subjectCode}`)
setTitle(title)

const watchers = filterWatchers()

onMounted(() => useQueryFilter())

onBeforeUnmount(() => {
  watchers.stopFilterWatchers()
  setCurrentSubject('')
  $reset()
})

watch(props, async () => {
  setCurrentSubject(props.subjectCode)
}, { immediate: true })

const isNew = computed(() => currentSubject.value && isNewSubject(currentSubject.value, selectedGrade.value))
const isNewView = computed(() =>  isNew.value && !selectedHeaders.value.length)
const isHeaderView = computed(() => isNew.value && selectedHeaders.value.length)

const { clear: clearAppColor } = useAppColor()

const componentMap = computed(() => {
  if (isNewView.value) return NewSubjectView
  if (isHeaderView.value) return HeaderView
  return LegacySubjectView
})

const componentProps = computed(() => {
  return componentMap.value === HeaderView ? {} : { subjectCode: props.subjectCode }
})

watch(componentMap, () => clearAppColor())
</script>

<template>
  <component
    :is="componentMap"
    v-if="hasLoaded"
    v-bind="componentProps"
  />
</template>
