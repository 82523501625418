<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import type { AggregationTermEntry } from '~/models/Content/Response'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsDrawer, KsIcon, KsInput, KsPagination, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { debounce } from '~/utils/functionUtils'
import { colorMap, getColorAndShade } from '~/utils/colors'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { PendoTrackName } from '~/models/Pendo'
import useText from '~/composables/useText'
import useSearchLogic from '~/composables/useSearchLogic'
import usePendo from '~/composables/usePendo'
import NoSearchResults from '~/components/search/NoSearchResults.vue'
import AggregationFilter from '~/components/search/AggregationFilter.vue'
import ActiveFilters from '~/components/search/ActiveFilters.vue'
import SearchCard from '~/components/cards/SearchCard.vue'

const PAGE_LIMIT = 12
const DEBOUNCE_MS = 500
const SPELLCHECK = true
const AGGREGATION_FIELDS = ['labels', 'activities']

const props = defineProps<{
  open: boolean
  subjectCode: SubjectCode
  gradeCode: GradeCode
  initialSearchQuery: string
  themeColor: string
}>()

const emit = defineEmits(['closeDialog'])

const { t } = useI18n()
const route = useRoute()
const { intersect, truthy } = arrayUtils()
const { matchingProducts } = storeToRefs(useProductStore())
const { pendoTrack } = usePendo()
const { capitalize } = useText()

const searchInput = ref<{ el: HTMLElement }>()
const searchQuery = ref('')
const page = ref(1)

const title = computed(() => t('search.subject', { subject: t(`metadata.subjects.${props.subjectCode}`).toLowerCase() }))
const backgroundColor = computed(() => colorMap.get(getColorAndShade(props.themeColor, '5'))?.rgb)
const complementaryColor = computed(() => colorMap.get(getColorAndShade(props.themeColor, '10'))?.rgb)
const hoverColor = computed(() => colorMap.get(getColorAndShade(props.themeColor, '20'))?.rgb)
const textColor = computed(() => colorMap.get(getColorAndShade(props.themeColor, '60'))?.rgb)
const hasQuery = computed(() => searchQuery.value.length > 2)

const splitQueryToArray = (name: string): string[] => ((route.query[name] || '') as string)
  .split(',').map((s) => s.trim()).filter(truthy)

const labelCriterion = ref<string[]>(splitQueryToArray('label'))
const activityCriterion = ref<string[]>(splitQueryToArray('activity'))

const subtreeCriterion = computed(() => matchingProducts.value
  .filter((product) => intersect(product.subjects, [props.subjectCode]).length > 0)
  .map((product) => product.aunivers.pathString as string))

const addLabelCriterion = (entry: string) => {
  const index = labelCriterion.value.findIndex((lb) => lb === entry)
  page.value = 1

  index > -1
    ? labelCriterion.value.splice(index, 1)
    : labelCriterion.value.unshift(entry)
}

function addActivityCriterion(activity: string) {
  const index = activityCriterion.value.findIndex((a) => a === activity)
  page.value = 1

  index > -1
    ? activityCriterion.value.splice(index, 1)
    : activityCriterion.value.unshift(activity)
}

const resetCriterions = () => {
  page.value = 1
  labelCriterion.value = []
  activityCriterion.value = []
}

const onInput = debounce(() => {
  if (hasQuery.value) pushPage(1)
  isTyping.value = false
}, DEBOUNCE_MS)

const pushPage = (pageNumber: number) => {
  page.value = pageNumber
  doSearch()
}

const resetLabelCriterion = () => labelCriterion.value = []
const resetActivityCriterion = () => activityCriterion.value = []

const {
  doSearch,
  items,
  isLoading,
  totalPages,
  totalCount,
  isTyping,
  noResults,
  labelAggregations,
  activityAggregations,
  hasFailed,
  spellingIncorrect,
  spellingSuggestion,
} = useSearchLogic(searchQuery, page, PAGE_LIMIT, SPELLCHECK, () => ({
  subtreeCriterion: subtreeCriterion.value,
  activityFieldCriterion: activityCriterion.value,
  gradeFieldCriterion: [props.gradeCode],
  labelFieldCriterion: labelCriterion.value ? labelCriterion.value : undefined,
  sortField: hasQuery.value ? 'score' : 'modified',
  sortOrder: 'desc',
  aggregationCriterion: AGGREGATION_FIELDS,
}), pendoTrack, PendoTrackName.SubjectSearch, {
  subjects: props.subjectCode || '',
  grades: props.gradeCode || '',
  labels: labelCriterion.value[0] || '',
})

function suggestedSearch(query: string) {
  searchQuery.value = query
  doSearch()
}

watch(props, () => {
  setTimeout(() => { // override KsDialog focus behaviour
    if (searchInput.value && props.open) searchInput.value.el.focus()
  }, 255)

  if (props.open) {
    searchQuery.value = props.initialSearchQuery || ''
    doSearch()
  } else {
    searchQuery.value = ''
    labelCriterion.value = []
    page.value = 1
  }
})

watch(searchQuery, () => {
  if (!searchQuery.value.length) {
    pushPage(1)
  }
}, { deep: true })

watch([labelCriterion, activityCriterion], () => {
  if (props.open && (labelCriterion.value || activityCriterion.value)) {
    pushPage(1)
  }
}, { deep: true })

watch(totalCount, () => {
  if (totalCount.value < PAGE_LIMIT) {
    page.value = 1
  }
})
</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDrawer
      position="bottom"
      :title="title"
      :open="open"
      size="95%"
      @close="emit('closeDialog')"
    >
      <template #body>
        <div class="mx-auto mb-8 flex max-w-screen-au grid-cols-1 flex-col gap-8 px-4 font-inter md:grid md:grid-cols-6 md:px-8">
          <div class="col-span-full space-y-8 md:col-span-2">
            <AggregationFilter
              v-if="labelAggregations.length"
              :title="t('search.contentTypes')"
              :is-loading="isLoading"
              :aggregations="labelAggregations"
              :criterions="labelCriterion"
              :translator="(entry) => t(`labels.${entry.key}`)"
              @add-criterion="addLabelCriterion"
            />
            <AggregationFilter
              v-if="activityAggregations.length"
              :title="t('activities.messages.title')"
              :is-loading="isLoading"
              :aggregations="activityAggregations"
              :criterions="activityCriterion"
              :translator="(entry: AggregationTermEntry) => t(`activities.${entry.key}`, capitalize(entry.key))"
              @add-criterion="addActivityCriterion"
            />
          </div>
          <div class="col-span-4">
            <form
              role="search"
              class="relative"
              @submit.prevent="() => {}"
            >
              <KsIcon
                id="magnifying-glass"
                class="absolute left-7 top-1/2 z-10 -translate-y-1/2 text-xl"
              />
              <KsInput
                ref="searchInput"
                v-model="searchQuery"
                type="text"
                maxlength="200"
                name="search"
                :aria-label="t('search.title')"
                shape="rounded"
                :placeholder="t('search.placeholder')"
                class="!bg-white !pl-16 !text-xl placeholder:text-lg placeholder:text-gray-40"
                @input="onInput(); isTyping = true"
              />
            </form>
            <div class="mt-6 flex items-center gap-6">
              <p
                v-if="!isLoading && items.length"
                class="flex flex-col text-gray-50"
              >
                <span
                  class="text-xl font-bold"
                  v-text="t('search.resultsCount', { count: totalCount })"
                />
                <span
                  class="text-xs"
                  v-text="t('search.currentPage', { page: page, totalPages: totalPages > 0 ? totalPages : page })"
                />
              </p>
              <KsSkeletonWrapper v-if="isLoading">
                <KsSkeleton
                  width="80px"
                  height="44px"
                  :background="complementaryColor"
                />
              </KsSkeletonWrapper>
              <ActiveFilters
                v-if="labelCriterion.length || activityCriterion.length"
                :label-criterion="labelCriterion"
                :activity-criterion="activityCriterion"
                @reset-label-criterion="resetLabelCriterion"
                @reset-activity-criterion="resetActivityCriterion"
                @reset-criterions="resetCriterions"
              />
            </div>
            <ul
              v-if="!isLoading"
              class="mt-6 grid grid-cols-1 gap-4 lg:grid-cols-2"
            >
              <SearchCard
                v-for="item in items"
                :key="`location-${item.locationId}`"
                :item="item"
                :theme-color="themeColor"
              />
            </ul>
            <KsSkeletonWrapper
              v-else
              class="mt-6 grid grid-cols-1 gap-4 lg:grid-cols-2"
            >
              <KsSkeleton
                v-for="i in PAGE_LIMIT"
                :key="i"
                height="120px"
                :background="complementaryColor"
              />
            </KsSkeletonWrapper>
            <NoSearchResults
              v-if="!isTyping"
              :no-results="noResults"
              :has-query="hasQuery"
              :has-failed="hasFailed"
              :is-loading="isLoading"
              :has-criterions="labelCriterion.length > 0"
              :spelling-suggestion="spellingIncorrect ? spellingSuggestion : ''"
              @reset-search="resetCriterions(); searchQuery = ''"
              @reset-criterions="resetCriterions"
              @suggested-search="suggestedSearch"
            />
            <KsPagination
              v-if="totalCount > PAGE_LIMIT && !isLoading"
              :current-page="page"
              :items-per-page="PAGE_LIMIT"
              :item-count="totalCount"
              class="mx-auto mt-12 flex justify-end gap-4"
              @push-page="pushPage"
            />
          </div>
        </div>
      </template>
    </KsDrawer>
  </Teleport>
</template>

<style scoped>
* {
  --ks-border: v-bind(complementaryColor);
  --ks-borderhoverfill: v-bind(complementaryColor);
  --ks-secondary: v-bind(textColor);
  --ks-input: v-bind(complementaryColor);
  --ks-inputhover: v-bind(hoverColor);
}

:deep(.ks-dialog.ks-drawer) {
  background: v-bind(backgroundColor);
}

:deep(.ks-dialog-header) {
  @apply max-w-screen-au mx-auto w-full !px-4 md:!px-8;
}
</style>
