<script setup lang="ts">
import type { ColorName } from '~/models/Content/Color'
import { computed, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { PendoFeature } from '~/models/Pendo'
import useFilterStore from '~/stores/filter'
import useProductStore from '~/stores/product'
import useAuthStore from '~/stores/auth'
import { useAppColor } from '~/composables/useAppColor'
import { useMedia } from '~/composables/useMedia'
import { useContentFilter } from '~/composables/useContentFilter'
import { useContentHelper } from '~/composables/useContentHelper'
import { useScrollBehavior } from '~/composables/useScrollBehavior'
import { useHeaderQuery } from '~/composables/useHeaderQuery'
import { colorMap } from '~/utils/colors'
import Image from '~/components/media/Image.vue'
import ColorCard from '~/components/cards/ColorCard.vue'
import GradeFilter from '~/components/ResourceFinder/GradeFilter.vue'
import SubjectFilter from '~/components/ResourceFinder/SubjectFilter.vue'
import HeaderFilter from '~/components/ResourceFinder/HeaderFilter.vue'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import HeaderTeacherButton from '~/components/subject/HeaderTeacherButton.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import HeaderViewBannerGrid from '~/components/utils/HeaderViewBannerGrid.vue'
import ScrollButton from '~/components/utils/ScrollButton.vue'

useScrollBehavior()

const { t } = useI18n()
const { lastSelectedHeader, productHeaders } = storeToRefs(useFilterStore())
const { isPackage } = useContentHelper()
const { filteredProductPackages, filteredContents } = useContentFilter()
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
const { isTeacher } = storeToRefs(useAuthStore())
const { set: setAppColor } = useAppColor()
const { isLoading, refetch, isRefetching } = useHeaderQuery()

const contentItems = computed(() => filteredContents.value.filter((content) => !isPackage(content)))
const mediaContentId = computed(() => lastSelectedHeader.value?.backgroundMedia && Number(lastSelectedHeader.value.backgroundMedia.destinationContentId))
const { media: bgMedia, isLoading: bgMediaIsLoading } = useMedia(mediaContentId)

const primaryColor = computed(() => lastSelectedHeader.value.colorPair?.primary)
const colorPair = computed(() => lastSelectedHeader.value.colorPair)
const skeletonColor = computed(() => getColor(colorPair.value?.secondary))
const dropdownBorderColor = computed(() => getColor(colorPair.value?.dark))
const isDark = computed(() => lastSelectedHeader.value.colorPair?.isDark)
const hasHeaders = computed(() => productHeaders.value.learningPath
  .some(({ locationId }) => locationId === lastSelectedHeader.value.locationId)
)
const showTeacherButton = computed(() => isTeacher.value
  && hasLoadedProducts.value
  && lastSelectedHeader
)

const bannerColor = computed(() => {
  const backgroundColor = lastSelectedHeader.value?.backgroundColor as ColorName
  return `background: ${colorMap.get(backgroundColor)?.rgb}`
})

const sectionColors = computed(() => {
  if (!colorPair.value) return []

  if (!filteredProductPackages.value.length) {
    return `background-color: ${getColor(colorPair.value?.dark)}; color: ${getColor(colorPair.value?.accent)};`
  } else {
    return filteredProductPackages.value.map((_, index) => {
      const backgroundColor = index % 2 === 0 ? getColor(colorPair.value?.dark) : getColor(colorPair.value?.primary)
      const textColor = isDark.value ? getColor(colorPair.value?.accent) : getColor(colorPair.value?.secondary)

      return `background-color: ${backgroundColor}; color: ${textColor};`
    })
  }
})

const getColor = (colorName: ColorName | undefined) => {
  if (!colorName) return ''
  const color = colorMap.get(colorName)
  return color ? color.rgb : ''
}

onMounted(
  () => primaryColor.value
  && setAppColor(lastSelectedHeader.value.backgroundColor as ColorName)
)

watch(filteredProductPackages, () => refetch())
</script>

<template>
  <HeaderViewBannerGrid :style="bannerColor">
    <h1
      class="sr-only"
      v-text="lastSelectedHeader.title"
    />
    <div
      id="top-dropdowns"
      class="flex gap-2 pt-24 grid-in-filters"
      style="--ks-input: white"
    >
      <SubjectFilter />
      <GradeFilter />
    </div>
    <div class="flex flex-col items-start gap-4 self-center pb-24 grid-in-header-info">
      <div
        v-if="hasHeaders"
        class="space-y-2"
      >
        <span
          class="text-sm font-bold uppercase tracking-wider"
          v-text="t('filters.chooseTheme')"
        />
        <HeaderFilter
          id="header-filter"
          style="--ks-input: white"
          :show-numbered-headers="false"
          :index="0"
          full-width
        />
      </div>
      <article
        v-if="lastSelectedHeader.intro"
        class="prose space-y-2 font-medium text-[currentColor] fluid-text-lg prose-p:my-0 hover:prose-a:text-seagreen-50 focus-visible:prose-a:ring"
      >
        <RichTextRenderer :text="lastSelectedHeader.intro" />
      </article>
      <HeaderTeacherButton
        v-if="showTeacherButton"
        :selected-header="lastSelectedHeader"
        :data-pendo="PendoFeature.TeacherContent.Header"
        variant="secondary"
        :style="{
          '--ks-input': 'rgb(var(--au-yellow-30))',
          '--ks-inputhover': 'rgb(var(--au-yellow-20))',
          'border': '2px solid rgb(var(--au-yellow-60))',
        }"
      />
    </div>
    <Image
      v-if="mediaContentId"
      :content="mediaContentId"
      :background="bgMediaIsLoading"
      height="0px"
      width="700px"
      class="aspect-video !h-auto min-h-full place-self-end object-cover object-left-bottom grid-in-image md:h-0 md:grid-in-[image-top_/_image-top_/_image]"
      :class="!bgMediaIsLoading && 'animate-fade'"
    />
    <CopyrightButton
      v-if="bgMedia && 'copyright' in bgMedia"
      :copyright="bgMedia.copyright"
      class="m-4 place-self-end row-end-image col-end-image"
    />
  </HeaderViewBannerGrid>
  <template v-if="isLoading || isRefetching">
    <section
      v-for="index in 2"
      :key="index"
      :style="sectionColors[index]"
    >
      <KsSkeletonWrapper class="mx-auto max-w-screen-au space-y-4 px-4 py-8 sm:px-8">
        <KsSkeleton
          height="36px"
          width="120px"
          :background="skeletonColor"
        />
        <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <KsSkeleton
            v-for="i in 4"
            :key="i"
            height="280px"
            :background="skeletonColor"
          />
        </ul>
      </KsSkeletonWrapper>
    </section>
  </template>
  <template v-else>
    <section
      v-for="(content, index) in filteredProductPackages"
      :key="content.contentId"
      :style="sectionColors[index]"
    >
      <div
        :id="`${index+1}`"
        class="mx-auto max-w-screen-au space-y-4 px-4 pb-16 pt-12 sm:px-8"
      >
        <h2
          class="font-calistoga fluid-text-2xl"
          v-text="content.title"
        />
        <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <li
            v-for="childContent in content.children"
            :key="childContent.locationId"
          >
            <ColorCard
              :resource="childContent"
              :loading="index === 0 ? 'eager' : 'lazy'"
            />
          </li>
        </ul>
      </div>
    </section>
  </template>
  <section
    v-if="contentItems.length > 0"
    :style="filteredProductPackages.length
      ? sectionColors[filteredProductPackages.length % sectionColors.length]
      : sectionColors
    "
  >
    <div class="mx-auto flex max-w-screen-au flex-col gap-4 px-4 pb-16 pt-12 sm:px-8">
      <h2
        class="font-calistoga fluid-text-2xl"
        v-text="t('labels.other')"
      />
      <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <li
          v-for="content in contentItems"
          :key="content.locationId"
        >
          <ColorCard :resource="content" />
        </li>
      </ul>
    </div>
  </section>
  <ScrollButton v-if="!isLoading && !isRefetching" />
</template>

<style scoped>
:deep(.ks-dropdown .ks-dropdown-list ul) {
  max-height: 620px;
  font-size: 1.15rem;
}

#top-dropdowns :deep(input) {
  font-size: .9rem;
}

#top-dropdowns :deep(.ks-dropdown svg) {
  font-size: .9rem;
}

#top-dropdowns :deep(.ks-dropdown .ks-dropdown-list ul) {
  font-size: .9rem;
}

#header-filter :deep(input) {
  font-size: 1.4rem;
  padding: .6em;
  height: 2.1em;
  padding-right: 4rem!important;
  font-family: 'Calistoga', serif;
}
#header-filter :deep(svg) {
  top: 15px;
}

#header-filter :deep(button) {
  top: 12px;
}

@media (min-width: 900px) {
  #header-filter :deep(input) {
    font-size: 1.9rem;
    padding: .6em;
    height: 2.1em;
  }
  #header-filter :deep(svg) {
    top: 24px;
  }

  #header-filter :deep(button) {
    top: 21px;
  }
}

#header-filter :deep(input) {
  border: 1px solid v-bind(dropdownBorderColor);
  border-radius: 10px;
}

</style>
